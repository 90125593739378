@font-face {
   font-family: Chango;
   src: url(https://fonts.googleapis.com/css?family=Chango);
}

.App {
  font-family: 'Koulen', cursive;
  font-size: large;
  text-align: center;

  /* Flex CSS */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100vw;
  height: 100vh;

  background-color: #5B2971;
  color: #848385;
}

.group {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
}

.label {
  position: absolute;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -ms-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
  pointer-events: none;
}

.input {
  width: 250px;
  background-color: transparent;
  border: none;
  border-bottom: solid 1px #6F537B;
  outline: none;
  color: #848385
}

.input:focus, .input:active {
  background-color: transparent;
}

.input:focus ~ label, .input:valid ~ label {
  margin-top: -25px;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -ms-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}

.button {
  background-color: #6F537B;
  color: white;
  font-weight: bolder;
  border: 0;
}

.next {
  border-radius: 40%;
  width: 2%;
  margin-top: 15%;
}

span {
  color: white;
}

b {
  margin-right: 4%
}

.red {
  color: red;
}

.final {
  background-color: brown;
}
